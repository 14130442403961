import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRoutes = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  { path: '/register', component: () => import('@/views/login/register'), hidden: true },
  {
    path: '',
    component: Layout,
    redirect: '/home',
    hidden: true,
    meta: { title: '首页', icon: 'house', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/home/index'),
      meta: { title: '首页', icon: 'house', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
    }],
  },
  { path: '/doc', component: () => import('@/views/doc'), hidden: true },
]

export const asyncRoutes = [

  {
    path: '/nt',
    component: Layout,
    redirect: '/nt/nkey',
    meta: { title: '广告主管理', icon: 'mobile-phone', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    name: 'nt',
    children: [
      {
        path: 'nkey',
        name: 'nkeylist',
        component: () => import('@/views/network/nkeys'),
        meta: { title: '广告主广告位', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'network',
        name: 'networklist',
        component: () => import('@/views/network/networks'),
        meta: { title: '广告主平台', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'ads',
        name: 'adList',
        component: () => import('@/views/ad/index'),
        meta: { title: '自主广告', roles: ["ROLE_ADXADMIN","ROLE_ADXDB",,"ROLE_ADXOP"] }
      },
    ]
  },
  {
    path: '/mid',
    component: Layout,
    redirect: '/mid/slot',
    meta: { title: '媒体管理', icon: 'mobile-phone', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    name: 'mid',
    children: [{
      path: 'slot',
      name: 'slotlist',
      component: () => import('@/views/media/slots'),
      meta: { title: '媒体广告位', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
    },
    // {
    //   path: 'slotAudit',
    //   name: 'slotAudit',
    //   component: () => import('@/views/media/slotsAudit'),
    //   meta: { title: '广告位审核', roles: ["ROLE_ADXADMIN"] }
    // },


    {
      path: 'app',
      name: 'applist',
      component: () => import('@/views/media/apps'),
      meta: { title: '媒体应用', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
    },
    {
      path: 'chl',
      name: 'chllist',
      component: () => import('@/views/media/channels'),
      meta: { title: '媒体平台', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
    },
    ]
  },
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/index',
    meta: { title: '财务结算', icon: 'money', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    name: 'finance',
    children: [
      {
        path: 'index',
        name: 'financeindex',
        component: () => import('@/views/finance/index'),
        meta: { title: '结算进度', roles: ["ROLE_ADXADMIN", "ROLE_ADXOP"] }
      },
      {
        path: 'dsp',
        name: 'financedsp',
        component: () => import('@/views/finance/dsp'),
        meta: { title: '广告主结算', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB","ROLE_ADXOP"] }
      },
      {
        path: 'ssp',
        name: 'financessp',
        component: () => import('@/views/finance/ssp'),
        meta: { title: '媒体结算', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB","ROLE_ADXOP"] }
      },
      {
        path: 'report',
        name: 'financereport',
        component: () => import('@/views/finance/report'),
        meta: { title: '综合结算报表', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'report-ssp',
        name: 'finance-report-ssp',
        component: () => import('@/views/finance/report_ssp'),
        meta: { title: '媒体结算报表', icon: 'data-analysis', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      }
    ]
  },


  {
    path: '/stat',
    component: Layout,
    redirect: '/stat/date/ssp',
    meta: { title: '统计报表', icon: 'data-analysis', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    name: 'stat',
    children: [
      {
        path: 'date/ssp',
        name: 'stat-date-ssp',
        component: () => import('@/views/stat/date-ssp'),
        meta: { title: '媒体流量统计', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'date/dsp',
        name: 'stat-date-dsp',
        component: () => import('@/views/stat/date-dsp'),
        meta: { title: '广告主流量统计', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'slot',
        name: 'stat-slot',
        component: () => import('@/views/stat/slot'),
        meta: { title: '按媒体汇总', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'nkey',
        name: 'stat-nkey',
        component: () => import('@/views/stat/nkey'),
        meta: { title: '按广告主汇总', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      }
    ]
  },
  {

    path: '/stat/rt',
    component: Layout,
    redirect: '/stat/rt/slot',
    meta: { title: '实时统计', icon: 'data-line', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    name: 'statrt',
    hidden: true,
    children: [
      {
        path: 'date/ssp',
        name: 'stat-rt-date-ssp',
        component: () => import('@/views/stat/date-ssp-rt'),
        meta: { title: '媒体实时流量', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'date/dsp',
        name: 'stat-rt-date-dsp',
        component: () => import('@/views/stat/date-dsp-rt'),
        meta: { title: '广告主实时流量', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'slot',
        name: 'stat-rt-slot',
        component: () => import('@/views/stat/slot-rt'),
        meta: { title: '按媒体汇总', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'nkey',
        name: 'stat-rt-nkey',
        component: () => import('@/views/stat/nkey-rt'),
        meta: { title: '按广告主汇总', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },

    ]
  },
  {

    path: '/stat/lts',
    component: Layout,
    redirect: '/stat/lts/slot',
    meta: { title: '实时统计LTS', icon: 'data-line', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] },
    name: 'statlts',

    children: [
      {
        path: 'date/ssp',
        name: 'stat-lts-date-ssp',
        component: () => import('@/views/stat/date-ssp-lts'),
        meta: { title: '媒体实时流量', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'date/dsp',
        name: 'stat-lts-date-dsp',
        component: () => import('@/views/stat/date-dsp-lts'),
        meta: { title: '广告主实时流量', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'slot',
        name: 'stat-lts-slot',
        component: () => import('@/views/stat/slot-lts'),
        meta: { title: '按媒体汇总', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'nkey',
        name: 'stat-lts-nkey',
        component: () => import('@/views/stat/nkey-lts'),
        meta: { title: '按广告主汇总', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },

    ]
  },
  {

    path: '/others',
    component: Layout,
    meta: { title: '其它统计', icon: 'data-analysis', roles: ["ROLE_ADXADMIN"] },
    name: 'others',

    children: [
      {
        path: 'limitStat',
        name: 'others-limit',
        component: () => import('@/views/others/limitStat'),
        meta: { title: '流量限流统计', roles: ["ROLE_ADXADMIN", "ROLE_ADXDB", "ROLE_ADXOP"] }
      },
      {
        path: 'adlog',
        name: 'adlog',
        component: () => import('@/views/monitor/adlog'),
        meta: { title: '广告报文抽样', roles: ["ROLE_ADXADMIN"] }
      },
      {
        path: 'sdklog',
        name: 'sdklog',
        component: () => import('@/views/monitor/sdklog'),
        meta: { title: '请求报文抽样', roles: ["ROLE_ADXADMIN"] }
      }
    ]

  },
  {

    path: '/system',
    name: 'system',
    component: Layout,
    redirect: '/system/user',
    meta: { title: '系统管理', icon: 'user', roles: ["ROLE_ADXADMIN"] },
    alwaysShow: true,
    children: [
      {
        path: 'user',
        name: 'system-user',
        component: () => import('@/views/system/user'),
        meta: { title: '用户管理', icon: 'user', roles: ["ROLE_ADXADMIN"] }
      },
      {
        path: 'log',
        name: 'system-log',
        component: () => import('@/views/system/llog'),
        meta: { title: '登录记录', icon: 'user', roles: ["ROLE_ADXADMIN"] }
      }
    ]
  },
  {

    path: '/links',
    component: Layout,
    meta: { title: '其它链接', icon: 'odometer', roles: ["ROLE_ADXADMIN"] },
    name: 'links',

    children: [
      {
        path: 'http://admin.adwanji.com:8848/nacos/',
        meta: { title: '配置服务', icon: 'link', roles: ["ROLE_ADXADMIN"] }
      },
      {
        path: 'http://admin.adwanji.com:8080/xxl-job-admin/',
        meta: { title: '定时任务', icon: 'link', roles: ["ROLE_ADXADMIN"] }
      },
      {
        path: 'http://admin.adwanji.com:9000/',
        meta: { title: '容器服务', icon: 'link', roles: ["ROLE_ADXADMIN"] }
      },
      {
        path: 'https://console.huaweicloud.com/ces/?agencyId=9da0d0071372419fab4c4135229a24bc&region=cn-north-4&locale=zh-cn#/overview/diyDashboard?dashboardId=db1689061769550VwjEXjXJx',
        meta: { title: '华为云监控', icon: 'link', roles: ["ROLE_ADXADMIN"] }
      },
      {
        path: 'https://sls.console.aliyun.com/lognext/project/wanji-adx/dashboard/dashboard-1689062775774-351334?%26token%3D%5B%5D',
        meta: { title: '服务器监控', icon: 'link', roles: ["ROLE_ADXADMIN"] }
      },
    ]

  },
  {

    path: '/sdkMgt',
    name: 'sdkManagement',
    component: Layout,
    redirect: '/sdkMgt/push',
    meta: { title: 'SDK管理', icon: 'user', roles: ["ROLE_ADXADMIN","ROLE_ADXOP"] },
    alwaysShow: true,
    children: [
      {
        path: 'push',
        name: 'SDKPush',
        component: () => import('@/views/sdkMgt/push'),
        meta: { title: 'SDK发布', roles: ["ROLE_ADXADMIN","ROLE_ADXOP"] }
      },
      {
        path: 'config',
        name: 'SDKConfig',
        component: () => import('@/views/sdkMgt/config'),
        meta: { title: 'SDK配置', roles: ["ROLE_ADXADMIN","ROLE_ADXOP"] }
      }
    ]
  },

  {
    path: '/ssp/slot',
    component: Layout,
    meta: { title: '广告位管理', roles: ["ROLE_SSPADMIN"] },
    children: [{
      path: 'index',
      name: 'ssp-slot-list',
      component: () => import('@/views/media/slots'),
      meta: { title: '广告位管理', icon: 's-order', roles: ["ROLE_SSPADMIN"] }
    }],
  },
  {
    path: '/ssp/app',
    component: Layout,
    meta: { title: '媒体管理', roles: ["ROLE_SSPADMIN"] },
    children: [{
      path: 'index',
      name: 'ssp-aoo-list',
      component: () => import('@/views/media/apps'),
      meta: { title: '媒体管理', icon: 'mobile-phone', roles: ["ROLE_SSPADMIN"] }
    }],
  },
  {
    path: '/ssp/report',
    component: Layout,
    redirect: '/ssp/index',
    meta: { title: '结算报表', icon: 'data-analysis', roles: ["ROLE_SSPADMIN"] },
    children: [{
      path: 'index',
      name: 'ssp-report-index',
      component: () => import('@/views/finance/report_ssp'),
      meta: { title: '结算报表', icon: 'data-analysis', roles: ["ROLE_SSPADMIN"] }
    }],
  },
  {
    path: '/pub/app',
    component: Layout,
    meta: { title: '媒体管理', roles: ["ROLE_PUBLISHER"] },
    children: [{
      path: 'index',
      name: 'pub-app-list',
      component: () => import('@/views/publisher/app'),
      meta: { title: '媒体应用', icon: 'mobile', roles: ["ROLE_PUBLISHER"] }
    }],
  },
  {
    path: '/pub/slot',
    component: Layout,
    meta: { title: '广告位管理', icon: 'c-scale-to-original', roles: ["ROLE_PUBLISHER"] },
    children: [{
      path: 'index',
      name: 'pub-slot-list',
      component: () => import('@/views/publisher/slot'),
      meta: { title: '广告位', icon: 'c-scale-to-original', roles: ["ROLE_PUBLISHER"] }
    }
    ],
  },
  {
    path: '/pub/report',
    component: Layout,
    meta: { title: '数据报表', icon: 'data-analysis', roles: ["ROLE_PUBLISHER"] },
    children: [{
      path: 'index',
      name: 'pub-report-index',
      component: () => import('@/views/publisher/report'),
      meta: { title: '数据报表', icon: 'data-analysis', roles: ["ROLE_PUBLISHER"] }
    }],
  },
  {
    path: '/pub/download',
    component: Layout,
    meta: { title: '下载', icon: 'files', roles: ["ROLE_PUBLISHER"] },
    children: [{
      path: 'index',
      name: 'pub-download-index',
      component: () => import('@/views/publisher/download'),
      meta: { title: '接入文档', icon: 'files', roles: ["ROLE_PUBLISHER"] }
    }],
  },
  {
    path: '/pub/billing',
    component: Layout,
    meta: { title: '结算中心', icon: 'bank-card', roles: ["ROLE_PUBLISHER"] },
    children: [{
      path: 'index',
      name: 'pub-billing-index',
      component: () => import('@/views/publisher/billing'),
      meta: { title: '结算管理', icon: 'bank-card', roles: ["ROLE_PUBLISHER"] }
    }],
  },
  {
    path: '/pub/account',
    component: Layout,
    meta: { title: '帐户信息', icon: 'user', roles: ["ROLE_PUBLISHER"] },
    children: [{
      path: 'index',
      name: 'pub-account-index',
      component: () => import('@/views/publisher/account'),
      meta: { title: '帐户信息', icon: 'user', roles: ["ROLE_PUBLISHER"] }
    }],
  },



  { path: '*', redirect: '/404', hidden: true }
]



const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router